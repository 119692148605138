function formValidate () {

    const sendBtn = document.querySelector('.feedback__btn');
    sendBtn.setAttribute('disabled', 'true');
    sendBtn.classList.add('disable');
    const inputName = document.querySelector('input[name="fb_name"]');
    const validTextForNameEn = document.querySelector('form label:first-child div .en');
    const firstTextForNameEn = 'Name';
    const validTextForNameRu = document.querySelector('form label:first-child div .ru');
    const firstTextForNameRu = 'Имя';
    const secondValidtextForNameEn = 'The name must be from 2 to 30 characters*';
    const secondValidtextForNameRu = 'Имя должно быть от 2 до 30 символов*';
    const NAME_REGEXP = /[a-zA-Zа-яА-я]/g;

    const inputText = document.querySelector('input[name="fb_message"]');
    const validTextForTextEn = document.querySelector('form label:nth-child(2) div .en');
    const validTextForTextRu = document.querySelector('form label:nth-child(2) div .ru');
    const firstTextForTextEn = 'Comment';
    const firstTextForTextRu = 'Комментарий';
    const secondValidtextForTextEn = 'The comment should be from 5 to 30 characters';
    const secondValidtextForTextRu = 'Комментарий должен быть от 5 до 30 символов';

    const inputEmail = document.querySelector('input[name="fb_email"]');
    const validTextForEmailEn = document.querySelector('form label:nth-child(3) div .en');
    const validTextForEmailRu = document.querySelector('form label:nth-child(3) div .ru');
    const firstTextForEmailEn = 'E-Mail';
    const firstTextForEmailRu = 'Элкутронная Почта';
    const secondValidtextForEmailEn = 'Please enter a valid email address*';
    const secondValidtextForEmailRu = 'Пожалуйста, введите действительный адрес электронной почты*';
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    function isInputNameValid(value) {
        return (NAME_REGEXP.test(value) && value.length > 2);
    }

    function isInputTextValid(value) {
        return (value.length > 5 && value.length < 30);
    }

    function isInputEmailValid(value) {
        return (EMAIL_REGEXP.test(value) && value.length > 8);
    }

    

    function validateInputs(nameOfInput, validLabelEn, validTextSecondEn, validTextFirstEn, validLabelRu, validTextSecondRu, validTextFirstRu,  validTest) {
        function disableBtn() {
            if(isInputEmailValid(inputEmail.value) && isInputNameValid(inputName.value)) {
                sendBtn.setAttribute('disabled', 'false');
                sendBtn.classList.remove('disable');
            } else {
                sendBtn.setAttribute('disabled', 'true');
                sendBtn.classList.add('disable');
            }
        }
        function onInput() {
            if(validTest(nameOfInput.value)) {
                validLabelEn.innerHTML = `${validTextFirstEn}`;
                validLabelEn.classList.remove('error');
                validLabelRu.innerHTML = `${validTextFirstRu}`;
                validLabelRu.classList.remove('error');
            } else {
                validLabelEn.innerHTML = `${validTextSecondEn}`;
                validLabelEn.classList.add('error');
                validLabelRu.innerHTML = `${validTextSecondRu}`;
                validLabelRu.classList.add('error');
            }
        }
        nameOfInput.addEventListener('blur', onInput);
        nameOfInput.addEventListener('blur', disableBtn);
    }
    
    validateInputs(inputName, validTextForNameEn, secondValidtextForNameEn, firstTextForNameEn, validTextForNameRu, secondValidtextForNameRu, firstTextForNameRu, isInputNameValid);

    validateInputs(inputText, validTextForTextEn, secondValidtextForTextEn, firstTextForTextEn, validTextForTextRu, secondValidtextForTextRu, firstTextForTextRu, isInputTextValid);

    validateInputs(inputEmail, validTextForEmailEn, secondValidtextForEmailEn, firstTextForEmailEn, validTextForEmailRu, secondValidtextForEmailRu, firstTextForEmailRu, isInputEmailValid);
}

module.exports = formValidate;