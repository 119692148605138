"use strict";
import localStorage from "./modules/localStorage";
import showMenu from "./modules/showMenu";
import browserVideo from "./modules/browserVideo";
import sendForm from "./modules/feedback";
import formValidate from "./modules/formValidate";
window.addEventListener("DOMContentLoaded", () => {

SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 700,
    // Размер шага в пикселях
    stepSize: 90,

    // Дополнительные настройки:

    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 1,

    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 50,

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 3,
    pulseNormalize: 1,

    // Поддержка тачпада
    touchpadSupport: true,
  });

  showMenu();
  browserVideo();
  localStorage();
  formValidate();
  sendForm();
});
