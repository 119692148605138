function sendForm() {
  let form = document.getElementById("form");
  // serverResponse = document.querySelector('.feedback__ok');

  form.addEventListener("submit", async function (event) {
    event.preventDefault();

    let response = await fetch("/mailer/mail.php", {
      method: "POST",
      body: new FormData(form),
    });

    if (response.ok) {
      console.log("send");
      form.classList.add("hidden");
      //   serverResponse.style.display = "block"
      gsap.to(".feedback__title", { autoAlpha: 0 });
      gsap.to(".feedback__form", { autoAlpha: 0 });
      gsap.to(".feedback__response", { autoAlpha: 1 });
    } else {
      console.log("error: " + response.status);
      form.classList.add("hidden");
      //   serverResponse.style.display = "block"
      // serverResponse.innerHTML = "Message could not be sent"
    }

    form.reset();

    serverResponse.addEventListener("click", function () {
      form.classList.remove("hidden");
      // serverResponse.style.display = "none"
    });
  });
}

module.exports = sendForm;
