function localStorage () {
    const eng = document.querySelector('#eng');
    const ru = document.querySelector('#ru');
    const engContent = document.querySelectorAll('.en');
    const ruContent = document.querySelectorAll('.ru');
    eng.classList.remove('header__lang--active');
    ru.classList.remove('header__lang--active');
    engContent.forEach(item => item.style.display = 'none');
    ruContent.forEach(item => item.style.display = 'none');
    if(window.localStorage.length === 0) {
        window.localStorage.setItem('eng', true);
    }
    switch (window.localStorage.getItem('eng')) {
        case 'true':
            eng.classList.add('header__lang--active');
            engContent.forEach(item => item.style.display = 'block');
            break;
        case 'false':
            ru.classList.add('header__lang--active');
            ruContent.forEach(item => item.style.display = 'block');
            break;
        
    }
    ru.addEventListener('click', () => {
        window.localStorage.setItem('eng', false);
        eng.classList.remove('header__lang--active');
        ru.classList.add('header__lang--active');
        engContent.forEach(item => item.style.display = 'none');
        ruContent.forEach(item => item.style.display = 'block');
    });
    eng.addEventListener('click', () => {
        window.localStorage.setItem('eng', true);
        eng.classList.add('header__lang--active');
        ru.classList.remove('header__lang--active');
        ruContent.forEach(item => item.style.display = 'none');
        engContent.forEach(item => item.style.display = 'block');
    })
    
}

export default localStorage;