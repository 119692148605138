function showMenu () {
    const burger = document.querySelector('.header__burger');
    const mobileMenu = document.querySelector('.header__mobile');
    burger.addEventListener('click', () => {
        mobileMenu.classList.toggle('header__mobile--active');
        burger.classList.toggle('header__burger--active');
        window.onscroll = () => window.scroll(0, 0)

    })
    
    if (mobileMenu.classList.contains('header__mobile--active')) {
        window.onscroll = () => window.scroll(0, 0)
    } else {
        
        const menuLinks = document.querySelectorAll(".header__link");
        menuLinks.forEach(item => {
            item.addEventListener('click', ()=> {
                mobileMenu.classList.toggle('header__mobile--active');
                burger.classList.toggle('header__burger--active');
                window.onscroll = '';
                let attr = item.getAttribute('data-href');
                console.log(attr)
                gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: attr });
            })
        })
    }
    

}
export default showMenu;